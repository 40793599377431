.rich-text-container h1 {
  @apply sm:!text-[32px] !text-[26px] !font-semibold;
}

.rich-text-container h2 {
  @apply sm:!text-[25px] !text-[20px] !font-semibold;
}

.rich-text-container h3 {
  @apply sm:!text-base !font-semibold;
}

.rich-text-container ul {
  @apply !list-disc !pl-6;
}

.rich-text-container ol {
  @apply !list-decimal !pl-6;
}

.rich-text-container {
  @apply !whitespace-pre-wrap;
}

.rich-text-container p:empty::after {
  content: "\00A0";
}

.rich-text-container p {
  @apply !m-0;
}

.rich-text-container a {
  @apply text-theme-primary hover:underline cursor-pointer;
}

.rich-text-container table, .rich-text-container th, .rich-text-container td, .rich-text-container tr {
  @apply !border !border-gray-300;
}